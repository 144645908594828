import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Main } from "./pages/Main";
import Login from "./pages/Login";
import Admin from "./pages/Admin";
import Offers from "./pages/Offers";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/vitr" element={<Main />} />
          <Route path="/b05afe82416" element={<Login />} />
          <Route path="/g05adb62456" element={<Admin />} />
          <Route path="/g05jgd62478" element={<Offers />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
